.naviconsrow {
  align-items: center;
  justify-content: flex-end;
  width: 90%;
}
.icon {
  margin-right: 20px;
  color: white;
}
.activNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  background-color: rgb(20, 26, 20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}
.disableNav {
  height: 35vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 99999999;
}

.biglogo {
  height: 70%;
}
.smalllogo {
  width: 8%;
}
.linklist {
  width: 30%;
  justify-content: space-evenly;
  margin-bottom: 30px;
  font-size: 15px;
}
.navLink {
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
}
.hamburgers {
  width: 35px;
  height: 5px;
  background-color: rgb(228, 228, 228);
  margin: 6px 0;
  z-index: 999999;
}
.Link {
  text-decoration: none;

  font-weight: 700;
}
.linkphon {
}
.display_on_phon {
  visibility: hidden;
  opacity: 0;
  display: none;
  height: 0;
}
.hamburger {
  color: #ffffff;
}
.linkphon {
  color: #ffffff;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  margin-bottom: 20px;
}
.closemenu {
  width: 100vw;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
}

.menuPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-evenly;
  background-color: #262626;
  transition: all 0.5s ease;
  z-index: 99999999;
}
.out {
  color: #ffffff;
  font-size: 2rem;
}
