.all_news {
  background-color: #262626;
  width: 100%;
}
.padincontact {
  background-color: white;

  margin: auto;
  margin-top: 250px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.padin {
  background-color: white;
  width: 1400px;
  margin: auto;
  margin-top: 250px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.headerPost {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 100;
  color: #262626;
}
.imagell {
  height: 100vh;
  width: 100%;
  margin-bottom: 10px;
}
.img {
  height: 100%;
  width: 100%;
}
.header3 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.words {
  width: 100%;
}
.rights {
  width: 98%;
}
.pergdiv {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.textpost {
  color: #262626;
  line-height: 23px;
  font-size: 1rem;
  width: 100%;
  font-weight: 100;
}
.wid70p {
  width: 84%;
}
.newspost {
  overflow: hidden;
}
.words {
  margin-bottom: 100px;
  direction: rtl;
}
.datepost {
  font-size: 1.1rem;
  letter-spacing: 5px;
  color: rgb(180, 180, 180);
  margin-bottom: 40px;
}
.news {
  margin-top: 250px;
  width: 75%;
  background-color: rgb(250, 250, 250);
}
.card-2 {
  height: 100vh;
  background-color: rgb(248, 248, 248);
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 100%;
  margin-bottom: 50px;
}
.image_haf {
  height: 47vh;
  width: 100%;
  outline: 1px solid rgb(219, 216, 216);
  outline-offset: -20px;
  background-color: rgb(92, 92, 92);
}

.info {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}
.titelsmall {
  margin-top: 30px;
  color: rgb(53, 53, 52);
}
.wid70 {
  width: 86%;
}
.date {
  color: rgb(153, 153, 153);
  font-size: 12px;
}
.preg {
  direction: rtl;
}
.header_news {
  direction: rtl;
  text-align: center;
}

.button {
  padding: 10px 20px 10px 20px;
  border-radius: 25px;
  background-color: black;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid black;
  margin-top: 40px;
  margin-bottom: 20px;
}
.button:hover {
  background-color: white;
  color: black;
}
