.infoself {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.sidenav {
  width: 30%;

  margin-left: 40px;
}
.imageself {
  height: 33vh;
  background-image: url("../image/sa.jpeg");
  object-fit: cover;
  background-size: cover;
  width: 95%;
}
.peregraf {
  text-align: center;
  direction: rtl;
  margin-top: 50px;
  width: 90%;
  color: rgb(100, 100, 100);
}
.name {
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.space {
  height: 80px;
}
.imageartical {
  width: 30%;
  height: 100%;
}
.headerartical {
  width: 65%;
  height: 100%;
  justify-content: space-between;
  direction: rtl;
}
.artical {
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  height: 10vh;
  cursor: pointer;
  margin-bottom: 20px;
}
