.footer {
  height: 30vh;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.w50 {
  width: 20%;
  height: 50%;
}
