/* .carousel-control-next-icon {
  color: #262626 !important;
  background-color: rgba(0, 0, 0, 0.219);
}
.carousel-control-prev-icon {
  color: #262626 !important;
  background-color: rgba(0, 0, 0, 0.219);
} */

/* .carousel-control-prev-icon {
  background-image: url("../image/d1.jpg") !important;
}

.carousel-control-next-icon {
  background-image: url("../image/d1.jpg") !important;
} */

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}
.imgfull {
  position: relative;
  z-index: -1;
}
.imgfull::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.466);
  z-index: 1;
}
.divi {
  height: 100%;
  width: 100%;

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
