body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  position: relative;
}

* {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
}

.titleheader {
  margin-top: 50px;
  margin-bottom: 50px;
}
.titlepost {
  margin-top: 50px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
.margin150 {
  margin-bottom: 150px;
}
.flexcol {
  display: flex;
  flex-direction: column;
}
.min-h100 {
  min-height: 100vh;
}
.flexrow {
  display: flex;
  flex-direction: row;
}
.flexrowtocol {
  display: flex;
  flex-direction: row;
}
.flexrowreverstocol {
  display: flex;
  flex-direction: row-reverse;
}
.flexrowrevers {
  display: flex;
  flex-direction: row-reverse;
}
.flexcenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flexrowcenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.wh100 {
  height: 100%;
  width: 100%;
}

.transition {
  transition: all 0.5s ease;
}
.over {
  overflow: hidden;
}
.bgwhite {
  background-color: white;
}
.w60 {
  width: 60%;
}
.w100 {
  width: 100%;
}
.margrip {
  margin-top: 200px !important;
}
.margbot {
  margin-bottom: 20px;
}
.margtop {
  margin-top: 20px;
}
.butn {
  padding: 18px 40px;
  border: 1px solid black;
  border-radius: 25px;
}
.primeryheaders {
  text-align: center;
  font-weight: 600;
  margin-top: 20px;

  font-size: 2.4rem;
}

@media only screen and (max-width: 1500px) {
  .paddingsides {
    width: 84% !important;
  }
}

@media only screen and (max-width: 1370px) {
  .padin {
    width: 80% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .gallery {
    width: 80% !important;
  }
  .preg {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .gridrow {
    display: flex !important;
    flex-direction: column !important;
  }
  .form {
    display: flex !important;
    flex-direction: column !important;
  }
  .paddingsides {
    width: 85% !important;
  }
  .buttonsend {
    width: 60% !important;
    margin: auto !important;
  }
  .flexrowtocol {
    flex-direction: column;
  }
  .flexrowreverstocol {
    flex-direction: column-reverse;
  }
  .sec {
    height: auto !important;
  }
  .text {
    width: auto !important;
  }
  .imagediv {
    width: auto !important;
  }
  .sidenav {
    width: 40% !important;
    margin-left: 0 !important;
    margin: auto !important;
  }
  .image_haf {
    height: 44vh !important;
  }
  .card-2 {
    height: auto !important;
  }
  .artical {
    align-items: flex-start !important;
    height: auto !important;
  }
  .wid70p {
    width: 90% !important;
    margin: auto !important;
  }
  .wid70 {
    width: 80% !important;
    margin: auto !important;
  }
  .w100 {
    width: 100%;
  }
  .rowne {
    height: auto !important;
  }
  .allIcons {
    width: 90% !important;
  }

  .hotestnews {
    margin-bottom: 70px;
    width: auto !important;
  }
  .butn {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .postSquars {
    flex-direction: column !important;
  }
  .cardsimilar {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .headerPost {
    text-align: center;
  }
  .grid-2 {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    outline: 1px solid rgb(255, 255, 255) !important;
    outline-offset: -15px !important;
  }

  .trans {
    transform: translateY(0) !important;
    opacity: 1 !important;
    visibility: visible !important;
    transition: all 0.5s ease;
    border: 1px solid white;
  }

  .padin {
    width: 90% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .all_mehiron {
    width: 100% !important;
  }
  .linklist {
    width: 60% !important;
  }
  .hwmy {
    height: 100vh !important;
    /* margin-bottom: 100px !important; */
  }

  .sidenav {
    width: 50% !important;
  }
}

@media only screen and (max-width: 800px) {
  .padin {
    width: 95% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .w30- {
    width: auto !important;
  }
  .naviconsrow {
    justify-content: space-evenly !important;
  }
  .icon {
    margin-right: 0 !important;
    color: white;
  }
  .display_on_phon {
    visibility: visible !important;
    opacity: 1 !important;
    display: block !important;
    height: auto !important;
    align-self: flex-start !important;
    padding-left: 20px !important;

    letter-spacing: 5px !important;
  }
  .answar {
    width: 85% !important;
    margin-left: 0 !important;
    margin: auto !important;
  }
  .display_non_on_phon {
    visibility: hidden !important;
    height: 0 !important;
    display: none !important;
    opacity: 0 !important;
  }
  .smalllogo {
    width: 40% !important;
    position: absolute !important;
  }

  .image {
    width: 300px !important;
    height: 300px !important;
  }
  .disableNav {
    max-height: 30vh !important;
  }
  .naviconsrow {
    margin-top: 10px;
  }
  .carousel-caption h3 {
    font-size: 2rem !important;
  }
  .grid {
    height: auto !important;
    grid-gap: 10px !important;
    grid-template-columns: 1fr !important;
  }
  .card {
    height: 40vh;
  }
  .table {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .carousel-caption {
    bottom: 10% !important;
  }
  .gallery {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .centerit {
    width: 95% !important;
  }
  .fourphotos {
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 7px !important;
    margin-bottom: 7px !important;
  }
  .grid4-4 {
    grid-template-columns: 1fr !important;
    grid-gap: 7px !important;
    margin-bottom: 7px !important;
  }
  .naviconsrow {
    width: 99% !important;
    z-index: 999999;
  }
}
@media only screen and (max-width: 600px) {
  .sidenav {
    width: 80% !important;
  }
}

@media only screen and (max-width: 500px) {
  .circulimg {
    margin-bottom: 50px !important;
  }
  .table {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .mehirontable {
    flex-direction: column-reverse !important;
    padding-bottom: 0px !important;
    margin-bottom: 10px;
  }
  .mehirontable2 {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center !important;
    padding-bottom: 0px !important;
    margin-bottom: 0 !important;
  }
  .txtmehir {
    margin-right: 0 !important;
    margin-right: 0px !important;

    margin-bottom: 0px !important;
  }
  .spacebetween {
    height: 0 !important;
  }
  .massager {
    padding: 0 !important;
  }
  .bgbi {
    padding-right: 10px;
    padding-left: 10px;
  }

  .imagecircul {
    height: 60px !important;
    width: 60px !important;
  }
}

.twit {
  background-color: royalblue;
  color: white !important;
}
.face {
  background-color: #3a5795;
  color: #ffff !important;
}
.inst {
  color: white !important;
  background-image: linear-gradient(45deg, #e80012, #c0008b);
}
.goog {
  color: red !important;
  background-color: rgb(255, 255, 255);
}
.waz {
  color: white !important;

  background-color: #44c052;
}



.nono{
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homeOpenBg {
  height: 100vh;
  width: 100%;

  object-fit: cover;
  background-size: cover;
  margin-bottom: 100px;
}
.cwhite {
  color: #fdfdff;
}
.carusel {
  height: 100%;
  width: 100%;
}
.carousel-caption {
  bottom: 20% !important;
}
.carousel-caption h3 {
  font-size: 3rem;
  font-weight: 900 !important;
  color: rgb(255, 255, 255);
  margin-bottom: 50px;
}
.carousel-item {
  height: 100% !important;
  font-size: 1.2rem;
}
.carousel-inner {
  height: 100%;
}
.imgfull {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
}
.callnow {
  padding: 15px 30px 15px 30px;
  border-radius: 25px;
  display: inline;
  font-weight: 900;
  border: 1px solid wheat;
  color: #fdfdff;
}
.address {
  margin-bottom: 50px;
}
.divcaruselaa {
  height: 100%;
  width: 100%;

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/d5.a943ceaf.jpg);
  background-size: cover;
  object-fit: cover;
}

.sec {
  height: 65vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.rowsection {
  width: 96%;
}
.bgblack {
}

.paddingsides {
  width: 74%;
  margin: auto;
}
.imagediv {
  height: 100%;
  width: 50%;
}
.image {
  width: 540px;
  height: 540px;
  object-fit: cover;
  background-size: cover;
  border-radius: 50%;
}
.text {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  direction: rtl;
}
.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.pere {
  text-align: center;
  line-height: 30px;
  font-weight: 700;
}

.circulimg {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-image: url(/static/media/d1.b34cbc94.jpg);
  margin: auto;
  background-size: cover;
  object-fit: cover;
  margin-bottom: 50px;
}
.spacebetween {
  justify-content: space-between;
}
.hwmy {
  height: 68vh;
  margin-bottom: 200px;
}
.cblack {
  color: black !important;
}
.txtblack {
  color: black !important;
  font-size: 15px;
  min-height: 20vh;
  direction: rtl;
}
.f {
  color: chartreuse;
}
.heder {
  font-weight: 900;
}

.bgbi {
  margin-bottom: 200px;
  height: 50vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/d6.62f1b02f.jpg);
  background-size: cover;
  object-fit: cover;
  color: #fdfdff;
  text-align: center;
}

.button2 {
  padding: 10px 40px 10px 40px;
  border-radius: 25px;
  border: 1px solid rgb(255, 255, 255);
  font-weight: 700;
  font-size: 2rem;
}

.grid {
  height: 95vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
}
.padinserves {
  background-color: white;
  width: 1400px;
  margin: auto;

  padding-left: 50px;
  padding-right: 50px;
}
.grid212 {
  height: 65vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.card2 {
  background-size: cover;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;

  transition: all 0.5s ease;
}
.card {
  background-size: cover;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  outline: 1px solid rgb(196, 190, 190);
  outline-offset: -20px;
  transition: all 0.5s ease;
}
.card:hover {
  outline: 1px solid rgb(255, 255, 255);
  outline-offset: -50px;
}
.card:hover .trans {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.trans {
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  border: 1px solid white;
}
.img1 {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.577),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/d1.b34cbc94.jpg);
}
.img2 {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/d2.15868a7f.jpg);
}
.img3 {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/d3.8a1c0d39.jpg);
}
.img4 {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/d5.a943ceaf.jpg);
}
.img5 {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/d6.62f1b02f.jpg);
}
.img6 {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/d7.fde3fcf4.jpg);
}

.mehirontable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  border: none !important;
  border-bottom: none !important;
  margin-bottom: 30px;
}
.mehironheader {
  direction: rtl;
}
.mehirontable2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.all_mehiron {
  color: white;
  background-color: #000000;
  width: 80%;

  margin: auto;
  margin-bottom: 200px !important;
}
.spacebetween {
  height: 1px;
  width: 50%;

  background-color: rgb(102, 101, 101);
}
.table {
  padding: 40px 80px 40px 80px;
  border: none !important;
  border-bottom: none !important;
}
.imagecircul {
  height: 50px;
  width: 50px;
  background-color: blueviolet !important;
  border-radius: 50%;
}
.txtmehir {
  font-weight: 700;
  color: white;
  direction: rtl;
  margin: 0;
  padding: 0 !important;
  margin-right: 20px;
  font-size: 16px;
}
.price {
  font-weight: 700;
  color: white;
}

.all_news {
  background-color: #262626;
  width: 100%;
}
.padincontact {
  background-color: white;

  margin: auto;
  margin-top: 250px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.padin {
  background-color: white;
  width: 1400px;
  margin: auto;
  margin-top: 250px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.headerPost {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 100;
  color: #262626;
}
.imagell {
  height: 100vh;
  width: 100%;
  margin-bottom: 10px;
}
.img {
  height: 100%;
  width: 100%;
}
.header3 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.words {
  width: 100%;
}
.rights {
  width: 98%;
}
.pergdiv {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.textpost {
  color: #262626;
  line-height: 23px;
  font-size: 1rem;
  width: 100%;
  font-weight: 100;
}
.wid70p {
  width: 84%;
}
.newspost {
  overflow: hidden;
}
.words {
  margin-bottom: 100px;
  direction: rtl;
}
.datepost {
  font-size: 1.1rem;
  letter-spacing: 5px;
  color: rgb(180, 180, 180);
  margin-bottom: 40px;
}
.news {
  margin-top: 250px;
  width: 75%;
  background-color: rgb(250, 250, 250);
}
.card-2 {
  height: 100vh;
  background-color: rgb(248, 248, 248);
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 100%;
  margin-bottom: 50px;
}
.image_haf {
  height: 47vh;
  width: 100%;
  outline: 1px solid rgb(219, 216, 216);
  outline-offset: -20px;
  background-color: rgb(92, 92, 92);
}

.info {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}
.titelsmall {
  margin-top: 30px;
  color: rgb(53, 53, 52);
}
.wid70 {
  width: 86%;
}
.date {
  color: rgb(153, 153, 153);
  font-size: 12px;
}
.preg {
  direction: rtl;
}
.header_news {
  direction: rtl;
  text-align: center;
}

.button {
  padding: 10px 20px 10px 20px;
  border-radius: 25px;
  background-color: black;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid black;
  margin-top: 40px;
  margin-bottom: 20px;
}
.button:hover {
  background-color: white;
  color: black;
}

.infoself {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.sidenav {
  width: 30%;

  margin-left: 40px;
}
.imageself {
  height: 33vh;
  background-image: url(/static/media/sa.fc5554b4.jpeg);
  object-fit: cover;
  background-size: cover;
  width: 95%;
}
.peregraf {
  text-align: center;
  direction: rtl;
  margin-top: 50px;
  width: 90%;
  color: rgb(100, 100, 100);
}
.name {
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.space {
  height: 80px;
}
.imageartical {
  width: 30%;
  height: 100%;
}
.headerartical {
  width: 65%;
  height: 100%;
  justify-content: space-between;
  direction: rtl;
}
.artical {
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  height: 10vh;
  cursor: pointer;
  margin-bottom: 20px;
}

.iconlinks {
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
}
.allIcons {
  width: 40%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
}
.icons {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid black;
  color: black;
}
.adminimage {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-image: url(/static/media/d1.b34cbc94.jpg);
  background-size: cover;
  object-fit: cover;
}

.postSquars {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.borderbot {
  border-bottom: 1px solid rgb(233, 230, 230);
  margin-bottom: 10px;
}
.all_similer {
  width: 100%;
  margin-bottom: 40px;
}
.headersmall {
  margin-top: 10px;
  font-size: 1.2rem;
}
.heder {
  font-weight: 100;
  margin-top: 50px;
  margin-bottom: 50px;
}
.cardsimilar {
  width: 30%;
  height: 100%;
  cursor: pointer;
}

.imgimg {
  height: 28vh;
}
.imgsimiler {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
}
.wordsdate {
  min-height: 14vh;
  direction: rtl;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ciculimage {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-size: cover;
  object-fit: cover;
  margin-bottom: 10px;
}
.rowne {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
}
.hotestnews {
  padding-left: 10px;
  padding-right: 10px;
}
.w30- {
  width: 30%;
}
.txthot {
  font-weight: 700;
  direction: rtl;
  text-align: center;
}
.i3 {
  background-image: url(/static/media/d1.b34cbc94.jpg);
}
.i2 {
  background-image: url(/static/media/d2.15868a7f.jpg);
}
.i1 {
  background-image: url(/static/media/d3.8a1c0d39.jpg);
}

.textarea {
  width: 100%;
  height: 20vh;
  border: 1px solid rgb(199, 198, 198);
  margin-bottom: 10px;
}
input:focus {
  outline: none;
}
.input {
  border: 1px solid rgb(199, 198, 198);
  margin-right: 10px;
  font-size: 1.5rem;
  height: 5vh;
  width: 100%;
  direction: rtl;
}
.divflex {
}
.form {
  padding-left: 5px;
}
.gridrow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.radio {
  margin-top: 10px;
  padding-left: 5px;
}
.buttonsend {
  padding: 10px 40px 10px 40px;
  margin-top: 20px;
  background-color: rgb(47, 49, 43);
  color: wheat;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
}

.infocoment {
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.comment {
  margin-bottom: 25px;
  margin-top: 25px;
}
.boto {
  border-bottom: 1px solid rgb(197, 197, 197);
  padding-bottom: 20px;
}
.answar {
  width: 80%;
  margin: auto;
}
.commentText {
}
.replay {
  margin-top: 20px;
  font-size: 0.9rem;
  cursor: pointer;
}
.imagecomment {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: antiquewhite;
  margin-right: 15px;
}
.namecomm {
  font-weight: 500;
  text-transform: capitalize;
  color: #262626;
}
.commentText {
  padding-top: 10px;
}
.datecom {
  letter-spacing: 3px;
  font-size: 0.8rem;
}
.margintopbottom {
  margin-top: 20px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgb(233, 230, 230);
}
.closeopen {
  height: 0;
  visibility: hidden;
}
.open {
  height: auto;
  width: 80%;
  margin: auto;
}
.op0vis0 {
  opacity: 0;
  height: 0px;
  visibility: hidden;
  display: none;
}

.naviconsrow {
  align-items: center;
  justify-content: flex-end;
  width: 90%;
}
.icon {
  margin-right: 20px;
  color: white;
}
.activNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  background-color: rgb(20, 26, 20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}
.disableNav {
  height: 35vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 99999999;
}

.biglogo {
  height: 70%;
}
.smalllogo {
  width: 8%;
}
.linklist {
  width: 30%;
  justify-content: space-evenly;
  margin-bottom: 30px;
  font-size: 15px;
}
.navLink {
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
}
.hamburgers {
  width: 35px;
  height: 5px;
  background-color: rgb(228, 228, 228);
  margin: 6px 0;
  z-index: 999999;
}
.Link {
  text-decoration: none;

  font-weight: 700;
}
.linkphon {
}
.display_on_phon {
  visibility: hidden;
  opacity: 0;
  display: none;
  height: 0;
}
.hamburger {
  color: #ffffff;
}
.linkphon {
  color: #ffffff;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  margin-bottom: 20px;
}
.closemenu {
  width: 100vw;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
}

.menuPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-evenly;
  background-color: #262626;
  transition: all 0.5s ease;
  z-index: 99999999;
}
.out {
  color: #ffffff;
  font-size: 2rem;
}

.all_gallery {
  justify-content: center;
  align-items: center;
  background-color: #262626;
}
.header {
  margin-top: 50px;
  margin-bottom: 50px;
}
.gallery {
  margin-top: 250px;
  width: 1300px;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
}
.centerit {
  width: 100%;
}
.fourphotos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.imgSort {
  height: 45vh;
}
.bigimage {
  height: 60vh;
}
.img {
  object-fit: cover;
  background-size: cover;
}
.grid4-4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.biggrid {
  height: 100%;
  width: 100%;
}

.disablepops {
  visibility: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.activpop {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  position: fixed;
  top: 0;
  left: 0;
}
.imagegallerypop {
  width: 90%;
  height: 90%;
  background-color: black;
}
.im {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
}
.out {
  padding: 10px 20px 10px 20px;
  background-color: #262626;
  color: white;
  font-size: 2rem;

  text-align: center;
}

/* .carousel-control-next-icon {
  color: #262626 !important;
  background-color: rgba(0, 0, 0, 0.219);
}
.carousel-control-prev-icon {
  color: #262626 !important;
  background-color: rgba(0, 0, 0, 0.219);
} */

/* .carousel-control-prev-icon {
  background-image: url("../image/d1.jpg") !important;
}

.carousel-control-next-icon {
  background-image: url("../image/d1.jpg") !important;
} */

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}
.imgfull {
  position: relative;
  z-index: -1;
}
.imgfull::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.466);
  z-index: 1;
}
.divi {
  height: 100%;
  width: 100%;

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.footer {
  height: 30vh;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.w50 {
  width: 20%;
  height: 50%;
}

